:root {
  --toastify-color-success: #219653 !important;
  --toastify-color-error: #df4759 !important;
  .Toastify__toast--info {
    background: #FFF;
    text-align: center;
    color: #2A3043;
 }
  .Toastify__toast--success {
    color: #219653;
    text-align: center;

 }

  .Toastify__toast--error {
    color: #e74c3c;
    text-align: center;

 }
 .Toastify__toast {
  border-radius: 10px; 
  }
}

body {

  box-sizing: border-box;
  font-family: sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


@font-face {
  font-family: "Cairo";
  src: url("./assets/fonts/Cairo-Regular.ttf"), local("Cairo");
}
html, body {
  height: 100%;
}

html,body {
  background-color: #ECECEC !important;
}


html{
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-family: Cairo !important;
  }
}

html[lang="ar"] {
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  button {
    font-family: Cairo !important;
  }
}
/**
  Default sizes:
  h1: 2em
  h2: 1.5em
  h3: 1.17em
  h4: 1em
  h5: .83em
  h6: .67em
  p: 1em
*/

h1 {
  font-size: clamp(1.1rem, 1.7266rem + 0.5981vw, 1.85rem) !important;
}
h2 {
  font-size: clamp(1.3rem, 1.3458rem + 0.7477vw, 1.8rem) !important;
}
h3 {
  font-size: clamp(1rem, 0.9911rem + 0.8673vw, 1.1rem) !important;
}
h4 {
  font-size: clamp(0.9rem, 0.9575rem + 0.4486vw, 1.2rem) !important;
}
h5 {
  font-size: clamp(0.8rem, 0.8075rem + 0.4486vw, 1rem) !important;
}
h6 {
  font-size: clamp(0.75rem, 0.6729rem + 0.3738vw, 1rem) !important;
}
p {
  font-size: clamp(0.7rem, 0.8229rem + 0.3738vw, 1rem) !important;
}

/* width */
::-webkit-scrollbar {
  width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #FFF;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777;
}