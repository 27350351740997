.link{
    width: 100%;
    padding: 0.5rem;
    text-decoration: none;
    color: #FFF;
    text-align: center;
}
.link:hover{
    
    background-color: #003D61;
    color: #FFF;

}