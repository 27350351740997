
@import '../../../assets/scss/colors.module.scss';
.wrapper{
    position: fixed;
    z-index: 100;
    width: 100%;
    background-color: #FFF;
    padding: 0.5rem 5rem;
    border-bottom: 1px solid #DEE2F8;
    @media (max-width:900px) {
        padding: 0.5rem 0.8rem;
        
    }
}

.container{


    

    display: flex;
    align-items: center;
    justify-content: space-between;

}

.notAuthContainer{

 
    display: flex;
    align-items: center;
    justify-content: flex-start;

}
.logo{
    object-fit: contain;
    width: 100px;
}
.subNavBar{
    width: 100%;
    padding: 0.8rem;
    padding-top: 4.5rem;
    background-color: #004D78;
    display: flex;
    align-items: center;
    justify-content: center;
    
 
}
.subNavBar > * {
    border-left: 0.5px solid #B2B5CB;
    padding-left: 10px;
  }
  
  .subNavBar > *:last-child {
    border-left: none;
    padding-left: 0;
  }

  .container > *:focus,
.container > *:active {
  border-color: #FFF;
}

  html[dir="ltr"]{
    .subNavBar > * {
        border-right: 0.5px solid #B2B5CB;
        padding-right: 10px;
        border-left: none;
      }
      
      .subNavBar > *:last-child {
        border-right: none;
        padding-right: 0;
      } 
 
  }

  
.Link{
    text-align: center;
    text-decoration: none;
    width: min(250px,100%);
    padding: 0.5rem;
    height: 100%;
    color: #FFF;
   
}
.Link:hover{
    
        background-color: #003D61;
        color: #FFF;
    
}
.ActiveLink{
  text-align: center;
  text-decoration: none;
  width: min(250px,100%);
  padding: 0.5rem;
  height: 100%;
  color: #FFF;
  background-color: #003D61;
  color: #FFF;
}
.ActiveLink:hover{
    
  background-color: #003D61;
  color: #FFF;

}
.language{
    color: #2A3043;
    line-height: 30px;
    cursor: pointer;
    margin: 0;
    font-weight: 500;
}
.subContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}